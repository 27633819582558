import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

function CareerFormOther({ page }) {
	const [state, handleSubmit] = useForm("mqkvlgzb");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3 " controlId="subject">
						<Form.Control
							value="Career Enquiry"
							className="pb-4 d-none"
							name="subject"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="Subject"
						field="subject"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label>NAME*</Form.Label>
						<Form.Control className="pb-4" name="name" required type="text" />
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />

					<Form.Group className="mb-3" controlId="telephone">
						<Form.Label>PHONE*</Form.Label>
						<Form.Control
							required
							className="pb-4"
							type="tel"
							name="telephone"
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="role">
						<Form.Label>WHICH ROLE?</Form.Label>
						<Form.Control
							value={page}
							className="pb-4"
							name="role"
							required
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="role" field="role" errors={state.errors} />
					<Form.Group className="mb-3" controlId="cv">
						<Form.Label>ATTACH CV (pdf, png, jpeg, Word document)</Form.Label>
						<Form.Control
							className="py-3 ps-4"
							name="cv"
							type="file"
							accept="image/png, image/jpeg, application/pdf, .pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
						/>
					</Form.Group>
					<ValidationError prefix="cv" field="cv" errors={state.errors} />

					<Form.Group className="mb-3" controlId="notes">
						<Form.Label>NOTES</Form.Label>
						<Form.Control
							className="py-3"
							name="notes"
							as="textarea"
							rows={5}
						/>
					</Form.Group>

					<ValidationError prefix="notes" field="notes" errors={state.errors} />

					{/* <div
				class="g-recaptcha"
				data-sitekey="6LdpZdQgAAAAAMGxG6mYjIg6joW2j_K9jJxjwnLN"
			></div> */}
					<div className="text-start">
						<Button
							variant="secondary"
							className="btn py-3 px-7 w-100 w-md-auto mt-3"
							style={{ borderRadius: "0px" }}
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Send
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3 " controlId="subject">
				<Form.Control
					value="Career Enquiry"
					className="pb-4 d-none"
					name="subject"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Subject" field="subject" errors={state.errors} />
			<Form.Group className="mb-3" controlId="name">
				<Form.Label>NAME*</Form.Label>
				<Form.Control className="pb-4" name="name" required type="text" />
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />

			<Form.Group className="mb-3" controlId="telephone">
				<Form.Label>PHONE*</Form.Label>
				<Form.Control required className="pb-4" type="tel" name="telephone" />
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="role">
				<Form.Label>WHICH ROLE?</Form.Label>
				<Form.Control
					value={page}
					className="pb-4"
					name="role"
					required
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="role" field="role" errors={state.errors} />
			<Form.Group className="mb-3" controlId="cv">
				<Form.Label>ATTACH CV (pdf, png, jpeg, Word document)</Form.Label>
				<Form.Control
					className="py-3 ps-4"
					name="cv"
					type="file"
					accept="image/png, image/jpeg, application/pdf, .pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				/>
			</Form.Group>
			<ValidationError prefix="cv" field="cv" errors={state.errors} />

			<Form.Group className="mb-3" controlId="notes">
				<Form.Label>NOTES</Form.Label>
				<Form.Control className="py-3" name="notes" as="textarea" rows={5} />
			</Form.Group>

			<ValidationError prefix="notes" field="notes" errors={state.errors} />

			{/* <div
				class="g-recaptcha"
				data-sitekey="6LdpZdQgAAAAAMGxG6mYjIg6joW2j_K9jJxjwnLN"
			></div> */}
			<div className="text-start">
				<Button
					variant="secondary"
					className="btn py-3 px-7 w-100 w-md-auto mt-3"
					style={{ borderRadius: "0px" }}
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					Send
				</Button>
			</div>
		</Form>
	);
}
export default CareerFormOther;
