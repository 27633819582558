import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import ServiceHero from "../components/service-hero";
import CarWhiteLogo from "../images/svg/mdi_car.svg";
import ClockWhiteLogo from "../images/svg/mdi_clock.svg";
import PoundWhiteLogo from "../images/svg/mdi_currency-gbp.svg";
import TeamWhiteLogo from "../images/svg/ri_team-fill.svg";
import CareerForm from "../components/career-form";
import CareerFormOther from "../components/career-form-other";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

const JobsTemplate = ({ data }) => {
  const { wpJobOpportunity } = data;

  const { siteUrl } = data.site.siteMetadata;

  const job = wpJobOpportunity;
  const alternatingColor = ["bg-primary", "bg-purple", "bg-secondary"];

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Careers",
        item: {
          url: `${siteUrl}/careers`,
          id: `${siteUrl}/careers`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: `${job.title}`,
        item: {
          url: `${siteUrl}/careers/${job.slug}`,
          id: `${siteUrl}/careers/${job.slug}`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={`${job.title} | ECF Car Care | Careers`}
        description={`${job.jobFields.metaDescription}`}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/careers/${job.slug}`,
          title: `${job.title} | ECF Car Care | Careers`,
          description: `${job.jobFields.metaDescription}`,
          images: [
            {
              url: `${job.jobFields.image.node.localFile.publicURL}`,
              width: `${job.jobFields.image.node.localFile.childImageSharp.original.width}`,
              height: `${job.jobFields.image.node.localFile.childImageSharp.original.height}`,
              alt: `${job.jobFields.image.node.altText}`,
            },
          ],
        }}
      />
      <section>
        <ServiceHero
          title="JOB OPPORTUNITY AT ECF"
          backgroundImage={
            job.jobFields.image.node.localFile.childImageSharp.gatsbyImageData
          }
        />
      </section>
      <Container className="my-6">
        <Row>
          <Col lg={8}>
            <h2 className=" text-uppercase pb-4">{job.title}</h2>
            <div className="">{parse(job.jobFields.description)}</div>
            <div className="text-center">
              <Button
                style={{ borderRadius: "0px" }}
                variant="secondary"
                href="#form"
                className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-8 "
              >
                Apply now
              </Button>
            </div>
          </Col>
          <Col lg={4}>
            <h3 className="fs-5">Salary</h3>
            <p>{job.jobFields.salary}</p>
            <h3 className="fs-5 pt-4">Features</h3>
            <Row className="">
              <div>
                {job.tags.nodes
                  .filter((item, index) => index < 7)
                  .map((feature, i) => (
                    <div
                      className={`px-3 py-2 text-center mb-3 d-inline-block
													  ${alternatingColor[i % alternatingColor.length]} me-3`}
                      style={{
                        background: "#EBEBEB",
                        borderRadius: "300px",
                      }}
                    >
                      <p
                        style={{ fontSize: "90%" }}
                        className="text-white pb-0 mb-0"
                      >
                        {feature.name}
                      </p>
                    </div>
                  ))}
              </div>
            </Row>
            <h3 className="fs-5 pt-4">Requirements</h3>
            <div className="">{parse(job.jobFields.requirements)}</div>
            <h3 className="fs-5 pt-4">Details</h3>
            <div className="">{parse(job.jobFields.details)}</div>
          </Col>
        </Row>
      </Container>
      <section className="gradient py-5 py-lg-7">
        <Container>
          <Row>
            <Col>
              <h2 className=" text-center text-white pb-5 text-uppercase">
                What we offer OUR PEOPLE
              </h2>
            </Col>
          </Row>
          <Row>
            <Col className="text-center" xs={6} lg={3}>
              <img
                src={ClockWhiteLogo}
                alt="Flexible working"
                className="pb-3 "
                style={{
                  maxWidth: "150px",
                }}
              />
              <p className="fs-4 inter-bold text-white">Flexible working</p>
            </Col>
            <Col className="text-center" xs={6} lg={3}>
              <img
                src={PoundWhiteLogo}
                alt="Competitive pay &  Performance bonus"
                className="pb-3 "
                style={{
                  maxWidth: "150px",
                }}
              />
              <p className="fs-4 inter-bold text-white">
                Competitive pay & Performance bonus
              </p>
            </Col>
            <Col className="text-center" xs={6} lg={3}>
              <img
                src={CarWhiteLogo}
                alt="Annual reviews"
                className="pb-3 "
                style={{
                  maxWidth: "150px",
                }}
              />
              <p className="fs-4 inter-bold text-white">Annual reviews</p>
            </Col>
            <Col className="text-center" xs={6} lg={3}>
              <img
                src={TeamWhiteLogo}
                alt="Company events"
                className="pb-3 "
                style={{
                  maxWidth: "150px",
                }}
              />
              <p className="fs-4 inter-bold text-white">Company events</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="form" className="pb-5  pt-5 py-lg-7">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <h2 className="text-center text-uppercase pb-4">
                Submit your details
              </h2>
              <div
                style={{ background: "#F9F9F9", borderRadius: "18px" }}
                className="p-md-5 p-4"
              >
                <CareerFormOther page={job.title} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default JobsTemplate;

export const jobData = graphql`
  query ($id: String!) {
    wpJobOpportunity(id: { eq: $id }) {
      title
      slug
      tags {
        nodes {
          name
        }
      }
      jobFields {
        description
        metaDescription
        details
        requirements
        salary
        image {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                original {
                  height
                  width
                }
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
